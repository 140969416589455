
import {VueLoading} from 'vue-loading-template';

export default {
  name: 'VLoading',
  components: {
    VueLoading,
  },
  props: {
    loading: {
      type: Boolean,
      require: true
    },
    styleObject: {
      type: Object,
      require: false,
      default: () => {
        return {
          padding: 12,
          width: 50,
          height: 50,
        }
      }
    },
    type: {
      type: String,
      require: false,
      default: () => {
        return "spin"
      }
    },
    color: {
      type: String,
      require: false,
      default: () => {
        return "#333"
      }
    }
  },
}
